import React, { useState, useEffect } from 'react';
import moment from 'moment';
require('moment/locale/pt');

export const CalendarDaysComponent = (props) => {
  const { initialDay, scrolled, setDays, days } = props;

  const getDays = () => {
    var days = [];
    for(var i = 0; i < 4; i++) {
      days.push(moment().add(initialDay + i, 'days').format('YYYY-MM-DD'));
    }
    setDays(days);
  }
  
  useEffect(() => {
    getDays();
  }, [initialDay])

  return days.length > 0 && (
    // <div>
    days.map((day, index) => {
      return <div style={{color: scrolled && '#ffffff'}} className="given-date" key={index}>
        <p style={{color: scrolled && '#ffffff'}}>{moment(day).format('ddd').toUpperCase()}</p>
        <h5 style={{color: scrolled && '#ffffff'}}>{moment(day).format('MMM D').toUpperCase()}</h5>
      </div>
    })
    // </div>
  )
}