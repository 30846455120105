import React from "react";
import styles from "./LInhaDeApoioFlow.module.css";
import {
  therapist,
  signup,
  schedule,
  download,
  appointment,
} from "../../assets/images/images";

const flow = [
  {
    page: "therapists",
    title: "Escolha seu psicologo",
    image:
      "https://elevewebapp-files.s3-sa-east-1.amazonaws.com/static/images/icons8-lady.png",
  },
  {
    page: "signup",
    title: "Crie sua conta na Moodar",
    image:
      "https://elevewebapp-files.s3-sa-east-1.amazonaws.com/static/images/icons8-login.png",
  },
  {
    page: "schedule",
    title: "Agende sua consulta",
    image:
      "https://elevewebapp-files.s3-sa-east-1.amazonaws.com/static/images/icons8-calendar.png",
  },
  {
    page: "download",
    title: "Baixe o App da Moodar",
    image:
      "https://elevewebapp-files.s3-sa-east-1.amazonaws.com/static/images/icons8-google-play.png",
  },
  {
    page: "compareça",
    title: "Compareça as consultas",
    image:
      "https://elevewebapp-files.s3-sa-east-1.amazonaws.com/static/images/icons8-webinar.png",
  },
];

const LinhaDeApoioFlow = ({ currentPage }) => {
  return (
    <div className={styles.content}>
      {flow.map((item) => (
        <div className={styles.item}>
          <div>
            <img
              src={item.image}
              style={{
                filter:
                  currentPage === item.page
                    ? "grayscale(0%)"
                    : "grayscale(100%)",
              }}
            />
          </div>
          <div className={styles.title}>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default LinhaDeApoioFlow;
