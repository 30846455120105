/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TherapistCard } from '../../commom-components';
import {
	fetchTherapists,
	retrieveTimeslots,
	preBook,
	retrieveFourDaysTimeslots,
} from './controller';
import { useSelector, useDispatch } from 'react-redux';
import {
	SearchComponent,
	TitleComponent,
	CalendarComponent,
	PaginateTherapists,
} from './components';
import { Spinner } from '../schedule/reusable';
import { TherapistActions } from '../../libs/redux/actions';
import './Therapist.css';

const Therapist = (props) => {
	const [loading, setLoading] = useState(true);
	const [expandAboutMe, setExpandAboutMe] = useState(false);
	const [therapistIndex, setTherapistIndex] = useState(null);
	const [approaches, setApproaches] = useState([]);
	const [domains, setDomains] = useState([]);
	const [days, setDays] = useState([]);
	const [duration, setDuration] = useState(40);
	const [nameFromHome, setNameFromHome] = useState('');
	const [perPage, setPerpage] = useState(10);
	const [isFiltered, setIsFiltered] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [selected, setSelected] = useState(0);
	const dispatch = useDispatch();
	const therapists = useSelector((state) => state?.therapists?.therapists);
	const filteredTherapists = useSelector((state) => state?.therapists?.filteredTherapists);
	const paginateTherapists = useSelector((state) => state?.therapists?.paginateTherapists);
	localStorage.clear();

	const history = useHistory();
	const aboutMe = (description, index) => {
		if (description) {
			return expandAboutMe && therapistIndex === index
				? description
				: description.substring(0, 144).concat('...');
		} else {
			return '';
		}
	};

	const saveAndPreBook = (time, therapistId, day) => {
		if (time !== '-') {
			localStorage.setItem(
				'therapistAndTime',
				JSON.stringify({ therapist: therapistId, schedule: time, day })
			);
			history.push('/signup');
		}
	};

	const getFieldValues = (therapists, field) => {
		const values = therapists?.map((therapist) => therapist[field].filter((field) => field));
		const uniqueValues = [...new Set(values.flat())];
		return uniqueValues;
	};

	const expand = (bool, index) => {
		setExpandAboutMe(!bool);
		setTherapistIndex(index);
	};

	useEffect(() => {
		const authToken = '4268be3aa771d905c70c2c9d0c17d74639ad23a9';
		therapists?.length === 0 &&
			!isFiltered &&
			fetchTherapists(authToken).then((therapists) => {
				dispatch(TherapistActions.addTherapists(therapists));
				dispatch(TherapistActions.addFilteredTherapists(therapists));
				dispatch(TherapistActions.addPaginateTherapists(therapists));
				setDomains(getFieldValues(therapists, 'domain_fields'));
				setApproaches(getFieldValues(therapists, 'approach'));
				// setLoading(false)
			});
	}, [isFiltered]);

	return (
		<div>
			<TitleComponent />
			<SearchComponent
				nameFromHome={nameFromHome}
				setLoading={setLoading}
				therapists={therapists}
				filteredTherapists={filteredTherapists}
				approaches={approaches}
				domains={domains}
				setIsFiltered={setIsFiltered}
				setSelected={setSelected}
				setCurrentPage={setCurrentPage}
			/>
			<CalendarComponent
				numberOfTherapists={isFiltered ? filteredTherapists?.length : therapists?.length}
				setDays={setDays}
				days={days}
				setLoading={setLoading}
				setDuration={setDuration}
				duration={duration}
			/>
			{!loading && (paginateTherapists?.length > 0 || isFiltered) ? (
				paginateTherapists?.map((therapist, index) => {
					return (
						<div key={index}>
							<TherapistCard
								setNameFromHome={setNameFromHome}
								index={index}
								therapist={therapist}
								expandAboutMe={(bool, index) => expand(bool, index)}
								expand={expandAboutMe}
								about={aboutMe(therapist?.description, index)}
								retrieveTimeslots={retrieveTimeslots}
								retrieveFourDaysTimeslots={retrieveFourDaysTimeslots}
								days={days}
								saveAndPreBook={saveAndPreBook}
								currentPage={currentPage}
								duration={duration}
							/>
						</div>
					);
				})
			) : (
				<div className="padding-vertical">
					<Spinner />
				</div>
			)}
			{(filteredTherapists?.length >= perPage ||
				filteredTherapists?.length !== therapists?.length) && (
				<PaginateTherapists
					perPage={perPage}
					arrayToPaginate={filteredTherapists}
					total={therapists?.length}
					isFiltered={isFiltered}
					setLoading={setLoading}
					setCurrentPage={setCurrentPage}
					setSelected={setSelected}
					currentPage={currentPage}
					selected={selected}
				/>
			)}
		</div>
	);
};

export default Therapist;
