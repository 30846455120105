import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Spinner, Toast } from '../../../commom-components';
import { NewTextInput } from '../../../commom-components/newtextinput/NewTextInput';
import TitleComponent from '../../../commom-components/title/TitleComponent';
import { LogoIMG } from '../../../assets/images/images';

import { SubmitButton } from '../../../commom-components/buttons/Buttons';
import styles from './newsignupform.module.css';

const ConcludeSignupForm = (props) => {
	const { saveFirstData, history, signUp, signUpForm } = props;

	console.log('signUpForm', signUpForm);
	// Parte 3 do cadastro
	const [telephone, setTelephone] = useState(undefined);
	const [name, setName] = useState(undefined);
	const [surname, setSurname] = useState(undefined);
	const [fetching, setFetching] = useState(false);

	const getValidatorSchema = () =>
		Yup.object().shape({
			name: Yup.string().required('Informe seu nome'),
		});

	const hasCompletedSignup = () => {
		let conditionValidation = false;

		if (signUpForm.accessType) {
			if (signUpForm.accessType === 'companyregistration') {
				conditionValidation = signUpForm.registration && signUpForm.company;
			} else if (signUpForm.accessType === 'family') {
				conditionValidation =
					(signUpForm.familyregistration && signUpForm.familycompany) ||
					(signUpForm.familycpf && signUpForm.familyemail);
			} else if (signUpForm.accessType === 'company') {
				conditionValidation = true;
			} else {
				conditionValidation = true;
			}
		} else {
			return false;
		}

		return (
			conditionValidation &&
			signUpForm.cpf &&
			signUpForm.email &&
			signUpForm.password &&
			signUpForm.email
		);
	};

	return (
		<div className={styles.register_content}>
			<Formik
				enableReinitialize
				initialValues={{
					telephone,
					name,
					surname,
				}}
				validationSchema={() => getValidatorSchema()}
				onSubmit={async (values) => {
					setFetching(true);
					const completedSignup = hasCompletedSignup();
					if (completedSignup) {
						const bodyToSend = {
							cpf: signUpForm.cpf,
							email: signUpForm.email,
							name: `${values.name} ${values.surname}`,
							phone: values.telephone,
							password: signUpForm.password,
							username: signUpForm.email,
							patient_type: signUpForm.accessType,
						};
						if (signUpForm.accessType.includes('company')) {
							if (signUpForm.accessType === 'companyregistration') {
								bodyToSend['registration'] = signUpForm.registration;
								bodyToSend['company_id'] = signUpForm.company;
							}
						} else if (signUpForm.accessType === 'family') {
							bodyToSend['company_email'] = signUpForm.familyemail;
							bodyToSend['company_cpf'] = signUpForm.familycpf;
							bodyToSend['company_registration'] = signUpForm.familyregistration;
							bodyToSend['company_id'] = signUpForm.familycompany;
						}
						signUp(bodyToSend)
							.then((res) => {
								if (res.error) {
									setFetching(false);
									return Toast(
										res.error.message
											? res.error.message
											: 'Não foi possivel concluir o cadastro',
										'error',
										4000
									);
								} else {
									history.push('/finished', { res });
								}
							})
							.catch((err) => {
								setFetching(false);
								return Toast(err.message ? err.message : err, 'error', 4000);
							});
					} else {
						setFetching(false);
						history.push({ pathname: '/signup' });
						return Toast(
							'Suas informações estão incompletas, voltando para o começo do cadastro',
							'error',
							4000
						);
					}
				}}
			>
				{() => (
					<Form className={styles.register_form}>
						<img src={LogoIMG} alt="Logo" />
						<TitleComponent text="Conclua seu cadastro" />

						<NewTextInput
							name={'telephone'}
							type={'text'}
							placeholder={'(99) 99999-9999'}
							label={'Telefone'}
							setItem={setTelephone}
							mask={'(99) 99999-9999'}
						/>
						<NewTextInput
							name={'name'}
							type={'text'}
							placeholder={''}
							label={'Nome'}
							setItem={setName}
						/>
						<NewTextInput
							name={'surname'}
							type={'text'}
							placeholder={''}
							label={'Sobrenome'}
							setItem={setSurname}
						/>
						{fetching ? (
							<Spinner />
						) : (
							<SubmitButton text="Criar Conta" handleClick={() => {}} />
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ConcludeSignupForm;
