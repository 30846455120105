import React from "react";
import LinhaDeApoioFlow from "../linhadeapoio-flow/LinhaDeApoioFlow";
import { logo_jj, saude } from "../../assets/images/images";

const Header = (props) => {
  //   const fullPath = "https://cadastro.moodar.com.br/psicologo/15/linhadeapoio";
  const fullPath = window.location.href;
  const linhadeapoio = fullPath.includes("linhadeapoio");
  return (
    <header class="site-header">
      <nav class="navbar navbar-expand-lg bg-white">
        <div class="container">
          <a class="navbar-brand" href="https://moodar.com.br">
            <img
              style={{ marginRight: 50, marginBottom: 10 }}
              src="https://firebasestorage.googleapis.com/v0/b/moodar-app.appspot.com/o/assets%2Flogo.png?alt=media&token=78056c3f-ab14-40fd-b3a5-ae1475690a79"
              alt="logo"
            />
            {linhadeapoio ? (
              <React.Fragment>
                <img style={{ marginRight: 50 }} src={logo_jj} alt="logo" />
                <img src={saude} alt="logo" />
              </React.Fragment>
            ) : null}
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
