import React from 'react';
import LayoutNewSignup from '../../../../commom-components/layoutsignup/LayoutNewSignup';
import TitleComponent from '../../../../commom-components/title/TitleComponent';
import { LogoIMG } from '../../../../assets/images/images';

import styles from './finished.module.css';

const FinishedScreen = () => {
	return (
		<LayoutNewSignup>
			<div className={styles.register_content}>
				<div className={styles.content}>
					<img src={LogoIMG} alt="Logo" />
					<TitleComponent text="Cadastro realizado com sucesso" />
					<p className={styles.smalltitle}>Acesse o app e usufrua do seu benefício!</p>
				</div>
			</div>
		</LayoutNewSignup>
	);
};

export default FinishedScreen;
