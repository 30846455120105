import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import { CalendarDaysComponent } from './CalendarDaysComponent';
import '../Therapist.css';

export const CalendarComponent = (props) => {
	const [initialDay, setInitialDay] = useState(1);
	const [color, setColor] = useState('#ffffff');
	const [scrolled, setScrolled] = useState(false);
	const { setDays, days, duration, setDuration } = props;
	const listenScrollEvent = () => {
		if (window.scrollY > 280) {
			setColor('#E51D49');
			setScrolled(true);
		} else {
			setColor('#ffffff');
			setScrolled(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);
		listenScrollEvent();
	}, [color]);
	const arrowClick = (addOrSubtract) => {
		addOrSubtract > 0 && setInitialDay(addOrSubtract);
	};

	const handleDurationChange = (event) => {
		const value = event.target.value;
		setDuration(value);
	};

	return (
		<div
			style={{ backgroundColor: color, color: scrolled ? '#ffffff' : '#313341' }}
			className="dates-section"
		>
			<div className="fullscreen center-block text-down">
				<div className="container">
					<div className="row">
						<div className="col-md-7 col-sm-7 counter-container">
							<label>Consultas de:</label>
							<select
								className="default-select"
								type="text"
								value={duration}
								onChange={handleDurationChange}
								style={{ color: scrolled && '#ffffff' }}
							>
								<option value="40" style={{ background: scrolled && '#e51d49' }}>
									40 minutos
								</option>
								{/* <option value="20" style={{ background: scrolled && '#e51d49' }}>
									20 minutos
								</option> */}
							</select>
						</div>
						<div className="col-md-5 col-sm-5 d-flex justify-content-between">
							<div className="dates-icon-right">
								<Icon onClick={() => arrowClick(initialDay - 1)}>
									arrow_back_ios
								</Icon>
							</div>
							<div className="dates-on-view row d-flex justify-content-between">
								<CalendarDaysComponent
									scrolled={scrolled}
									color
									initialDay={initialDay}
									setDays={setDays}
									days={days}
								/>
							</div>
							<div className="dates-icon-left">
								<Icon onClick={() => arrowClick(initialDay + 1)}>
									arrow_forward_ios
								</Icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
