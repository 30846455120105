import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { AuthController } from '../../../libs/controllers';
import { Spinner, Toast } from '../../../commom-components';
import { NewCheckbox, NewTextInput } from '../../../commom-components/newtextinput/NewTextInput';
import TitleComponent from '../../../commom-components/title/TitleComponent';
import { LogoIMG } from '../../../assets/images/images';

import styles from './newsignupform.module.css';
import { SubmitButton } from '../../../commom-components/buttons/Buttons';
import { isValidCpf, isValidEmail } from '../../../utils/General';

Yup.addMethod(Yup.string, 'emailValidation', function (errorMessage) {
	return this.test(`email`, errorMessage, function (value) {
		return isValidEmail(value);
	});
});

Yup.addMethod(Yup.string, 'cpfValidation', function (errorMessage) {
	return this.test(`cpf`, errorMessage, function (value) {
		return isValidCpf(value);
	});
});

const NewSignupForm = (props) => {
	const { saveFirstData, history, signUp } = props;

	// Parte 1 do cadastro
	const [email, setEmail] = useState(undefined);
	const [cpf, setCpf] = useState(undefined);
	const [password, setPassword] = useState(undefined);
	const [checkterms, setCheck] = useState(false);
	const [fetching, setFetching] = useState(false);

	const checkEmail = (email, cpf) =>
		AuthController.verifyEmail(email)
			.then((res) => {
				return AuthController.patientHasCompanyBond(email, cpf)
					.then(() => {
						return { success: true };
					})
					.catch(() => {
						return { message: 'No company for this registration' };
					});
			})
			.catch((err) => {
				return { error: 'Email já foi utilizado, por favor escolha outro' };
			});

	const getValidatorSchema = () =>
		Yup.object().shape({
			email: Yup.string()
				.emailValidation('Email precisa ser válido')
				.required('Email é obrigatório'),
			cpf: Yup.string().cpfValidation('CPF precisa ser válido').required('CPF é obrigatório'),
			password: Yup.string().required('Senha obrigatória'),
			checkterms: Yup.bool().oneOf([true], 'Atenção! Você deve aceitar os termos'),
		});

	return (
		<div className={styles.register_content}>
			<Formik
				enableReinitialize
				initialValues={{
					email,
					cpf,
					password,
					checkterms,
				}}
				validationSchema={() => getValidatorSchema()}
				onSubmit={(values) => {
					// const body = handleBodyToReq(values);
					setFetching(true);
					checkEmail(email, cpf)
						.then((res) => {
							setFetching(false);
							console.log('res', res);
							if (res.error) {
								return Toast(res.error, 'error', 4000);
							} else if (res.success) {
								// go to Parte 3
								values['accessType'] = 'company';
								values['registration'] = '';
								saveFirstData(values);
								history.push({ pathname: '/conclude-signup' });
								return;
							} else {
								saveFirstData(values);
								history.push({ pathname: '/access-type' });
							}
						})
						.catch((err) => {
							setFetching(false);
							return Toast(String(err), 'error', 4000);
						});
				}}
			>
				{() => (
					<Form className={styles.register_form}>
						<img src={LogoIMG} alt="Logo" />
						<TitleComponent text="Crie sua conta" />
						<NewTextInput
							name={'email'}
							type={'email'}
							placeholder={'exemplo@moodar.com.br'}
							label={'E-mail'}
							setItem={setEmail}
						/>

						<NewTextInput
							name={'cpf'}
							type={'text'}
							placeholder={'000.000.000-00'}
							label={'CPF'}
							setItem={setCpf}
							mask={'999.999.999-99'}
						/>

						<NewTextInput
							name={'password'}
							type={'password'}
							placeholder={'super_secreta'}
							label={'Senha'}
							setItem={setPassword}
						/>

						<NewCheckbox
							name={'checkterms'}
							placeholder={'Você aceita os termos'}
							label={'Concordo com os '}
							terms={true}
							setItem={setCheck}
						/>
						{fetching ? (
							<Spinner />
						) : (
							<SubmitButton text="Criar Conta" handleClick={() => {}} />
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default NewSignupForm;
