// @flow
import Schedule from '../views/schedule/Schedule';
import SignUp from '../views/signup/SignUp';
import Therapist from '../views/therapist/Therapist';
import AccessTypeScreen from '../views/signup/NewSignup/AccessType/AccessType';
import ConcludeSignupScreen from '../views/signup/NewSignup/ConcludeSignup/ConcludeSignup';
import FinishedScreen from '../views/signup/NewSignup/Finished/Finished';

export const routes: Array<{
	path: string,
	component: React$ComponentType<*>,
	isPrivate: boolean,
}> = [
	{ path: '/schedule', component: Schedule, isPrivate: false },
	{ path: '/SignUp', component: SignUp, isPrivate: false },
	{ path: '/therapist', component: Therapist, isPrivate: false },
	{ path: '/access-type', component: AccessTypeScreen, isPrivate: true },
	{ path: '/conclude-signup', component: ConcludeSignupScreen, isPrivate: true },
	{ path: '/finished', component: FinishedScreen, isPrivate: true },
];
