import { TherapistTypes } from "../types";

export const addTherapists = (data) => ({
  type: TherapistTypes.ADD_THERAPISTS,
  payload: data,
});

export const addFilteredTherapists = (data) => ({
  type: TherapistTypes.ADD_FILTERED_THERAPISTS,
  payload: data,
});

export const addPaginateTherapists = (data) => ({
  type: TherapistTypes.ADD_PAGINATE_THERAPISTS,
  payload: data,
});

export const clearTherapists = () => ({
  type: TherapistTypes.CLEAR_THERAPISTS,
  payload: [],
});
