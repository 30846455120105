import React, { useEffect } from 'react';
import { PersonDesktopIMG } from '../../assets/images/images';

import styles from './LayoutNewSignup.module.css';
const LayoutNewSignup = (props) => {
	const { children } = props;

	useEffect(() => {
		// TODO: verificar se não caga nas outras páginas
		document.getElementById('site-footer').style.display = 'none';
		document.querySelector('.site-header').style.display = 'none';
	}, []);

	return (
		<div className={styles.signup_content}>
			<div className={styles.left_content}>{children}</div>
			<div className={styles.right_content}>
				<img className={styles.img} src={PersonDesktopIMG} alt="Person on desktop" />
			</div>
		</div>
	);
};

export default LayoutNewSignup;
