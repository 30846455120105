import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { routes } from './constants/Routes';
import './App.css';
import Header from './commom-components/header/Header';

const App = () => {
	return (
		<HashRouter>
			<Header />
			<Switch>
				<Route exact path="/" render={() => <Redirect exact to="/signup" />} />
				{routes.map((r) => {
					const { isPrivate, path, ...props } = r;
					const Routable = Route;
					return <Routable key={path} path={path} {...props} />;
				})}
			</Switch>
		</HashRouter>
	);
};

export default App;
