import React from "react";
import styles from "./Toast.module.css";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

const handleBackgroundColors = (type) =>
  ({ error: "#E25E5E", sucess: "#E25E5E" }[type]);

const handleColors = (type) => ({ error: "white", sucess: "black" }[type]);

const ToastDiv = (props) => {
  const { title, type } = props;
  return (
    <div
      className={styles.toast}
      style={{
        color: handleColors(type),
        backgroundColor: handleBackgroundColors(type),
      }}
    >
      {title}
    </div>
  );
};

export const Toast = (message, type, duration) => {
  return toast.notify(() => <ToastDiv title={message} type={type} />, {
    position: "top",
    duration: duration || null,
  });
};
