import { TherapistTypes } from "../types";

const initialState = {
  therapists: [],
  filteredTherapists: [],
  paginateTherapists: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  // console.log("payload", payload);

  switch (type) {
    case TherapistTypes.ADD_THERAPISTS:
      return {
        ...state,
        therapists: [
          ...payload
        ]
      };
      case TherapistTypes.ADD_FILTERED_THERAPISTS:
        return {
        ...state,
        filteredTherapists: payload
      };
      case TherapistTypes.ADD_PAGINATE_THERAPISTS:
        return {
        ...state,
        paginateTherapists: payload
      };
    case TherapistTypes.CLEAR_THERAPISTS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
