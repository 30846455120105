import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	makeStyles,
	styled,
} from '@material-ui/core';
import ReactInputMask from 'react-input-mask';

import styles from './NewTextInput.module.css';

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: '#212331',
	},
	'& .MuiInput-underline:before': {
		borderBottom: '0',
	},
	'& .MuiInput-underline:after': {
		borderBottom: '0',
	},
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderBottom: '0',
	},
	'& .MuiInputBase-input': {
		borderBottom: '0',
	},
	'& .MuiFormLabel-root.Mui-focused': {
		marginTop: '8px!important',
		color: '#212331',
		fontWeight: '600',
		padding: '4px',
		zIndex: 9,
	},
	'& .MuiFormLabel-root.MuiFormLabel-filled': {
		marginTop: '8px!important',
		color: '#212331',
		padding: '4px',
		zIndex: 9,
	},
});

export const NewTextInput = (props) => {
	const { label, name, type, placeholder, setItem, mask } = props;

	return (
		<div className={styles.input}>
			{mask ? (
				<ReactInputMask
					mask={mask}
					value={props.value}
					onChange={(e) => {
						setItem(e.target.value);
					}}
				>
					{() => (
						<CssTextField
							label={label}
							name={name}
							type={type}
							placeholder={placeholder}
							inputProps={{
								style: {
									border: '1px solid #AAAAAA',
									borderRadius: '12px',
									padding: '12px',
								},
							}}
							InputLabelProps={{
								style: { marginLeft: 12, marginTop: 8, backgroundColor: '#ffffff' },
							}}
						/>
					)}
				</ReactInputMask>
			) : (
				<CssTextField
					label={label}
					name={name}
					placeholder={placeholder}
					onChange={(e) => {
						setItem(e.target.value);
					}}
					type={type}
					inputProps={{
						style: {
							border: '1px solid #AAAAAA',
							borderRadius: '12px',
							padding: '12px',
						},
					}}
					InputLabelProps={{
						style: { marginLeft: 12, marginTop: 8, backgroundColor: '#ffffff' },
					}}
				/>
			)}
			<ErrorMessage component="div" className={styles.error} name={name} />
		</div>
	);
};

export const NewCheckbox = (props) => {
	const { label, name, terms, setItem } = props;
	return (
		<div>
			<div className={styles.checkbox}>
				<input
					className={styles.checkfield}
					type={'checkbox'}
					name={name}
					onChange={(e) => setItem(e.target.checked)}
				/>
				<label className={styles.checkfieldText}>
					{label}
					{terms ? (
						<span>
							<a
								href={'https://moodar.com.br/termos-de-uso'}
								style={{ textDecorationLine: 'underline' }}
								target="_blank"
								rel="noopener noreferrer"
							>
								Termos de uso
							</a>{' '}
							e{' '}
							<a
								href={'https://moodar.com.br/politica-de-privacidade'}
								style={{ textDecorationLine: 'underline' }}
								target="_blank"
								rel="noopener noreferrer"
							>
								Política de Privacidade
							</a>
						</span>
					) : null}
				</label>
			</div>
			<div>
				<ErrorMessage component="div" className={styles.error} name={name} />
			</div>
		</div>
	);
};

export const NewSelect = (props) => {
	const { label, name, setItem, options } = props;
	const [isSelected, setIsSelected] = useState(false);

	return (
		<div className={styles.input}>
			<FormControl className={styles.formcontrol}>
				<InputLabel className={`${styles.labelselect} ${isSelected ? styles.active : ''}`}>
					{label}
				</InputLabel>
				<Select
					labelId="demo-simple-select-label"
					id={styles.demo_simple_select}
					label={label}
					onChange={(e) => {
						const value = e.target.value;
						setIsSelected(!!value);
						setItem(e.target.value);
					}}
					sx={{ borderRadius: '12px' }}
					className={styles.newselect}
				>
					{options.map((option, index) => {
						return (
							<MenuItem value={option.value} key={`option_${label}_${index}`}>
								{option.text}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
			<ErrorMessage component="div" className={styles.error} name={name} />
		</div>
	);
};
