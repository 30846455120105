import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./Store";
import firebase from "firebase";
import firebaseProperties from "./firebase.properties.json";

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

firebase.initializeApp(firebaseProperties);
ReactDOM.render(<Root />, document.getElementById("site-root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
