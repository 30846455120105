import { AuthAPI } from '../../server';
import { FAuth } from '../firebase';
import { SignInActions } from '../redux/actions';
import { StoreData, dispatch } from './Store';
import { AuthController } from '.';

export const verifyPatientCompany = (body, history) => {
	AuthAPI.verifyPatientCompany(body)
		.then((res) => history.push('/cpf'))
		.catch((err) => history.push('/signupcompany'));
};

const handleSignUpPath = (data) => {
	const { patient_type } = data;
	// eslint-disable-next-line default-case
	if (patient_type === 'family') {
		return AuthAPI.signUpAnonymously({ name: data.name, uid: data.uid })
			.then((session) => {
				return AuthAPI.signUpFamilyPatient({ ...data, id: session.user.id });
			})
			.catch((err) => {
				// TODO: precisa deletar o usuário criado
				return { error: err };
			});
	} else if (patient_type.includes('company')) {
		return AuthAPI.signUpCompanyPatient(data);
	} else {
		return AuthAPI.signupParticipant(data);
	}
};

export const signUp = (thunk, data) => {
	// const disp = dispatch();
	const { email, password } = data;
	console.log('dataaaaaaa', data);
	return FAuth.signUp(email, password)
		.then((res) => {
			return handleSignUpPath({ ...data, uid: res.user.uid, platform: 'web' })
				.then((res) => {
					const { user } = res;
					localStorage.setItem('user', JSON.stringify(user));
					// saveIds(disp, { userId: user.id });
					return res;
				})
				.catch((err) => {
					console.log('error no signup', err);
					res.user.delete();
					throw err;
				});
		})
		.catch((err) => {
			throw err;
		});
};

export const retrieveCompanies = async () => {
	return AuthAPI.retrieveCompanies().then((res) => res.companies);
};

export const verifyEmail = (email) =>
	AuthAPI.verifyEmail({ email })
		.then((res) => res.success)
		.catch((err) => {
			throw err;
		});

export const patientHasCompanyBond = (email, cpf) =>
	AuthAPI.verifyPatientEmail(email, cpf)
		.then((res) => {
			console.log('res', res);
			return true;
		})
		.catch((err) => {
			throw err;
		});

export const storeFirstData = (dispatch, data) => {
	return dispatch(SignInActions.updateForm(data));
};

export const getStoreData = () => {
	return StoreData();
};

export const storeIds = (dispatch, data) => {
	return dispatch(SignInActions.saveIds(data));
};
