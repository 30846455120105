import { SignInTypes } from "../types";

export const updateForm = (data) => ({
  type: SignInTypes.SAVE_FIRST_FORM,
  payload: data,
});

export const saveIds = (data) => ({
  type: SignInTypes.SAVE_USER_AND_THERAPIST_ID,
  payload: data,
});
