/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../views/schedule/reusable';
import './TherapistCard.css';
import { star } from '../../../assets/icons/icons.js';
import { TestimonialComponent } from '../../../views/therapist/components';

export const TherapistCard = (props) => {
	const {
		setNameFromHome,
		therapist,
		expandAboutMe,
		index,
		about,
		expand,
		days,
		retrieveFourDaysTimeslots,
		saveAndPreBook,
		duration,
	} = props;
	const { name, crp, approach, domain_fields, image } = therapist;
	const [times, setTimes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingTimes, setLoadingTimes] = useState(true);
	const [threeApproaches, setThreeApproaches] = useState([]);
	const firstName = name?.split(' ')[0];
	// const filteredTherapists = useSelector((state) => state?.therapists?.filteredTherapists);
	const paginateTherapists = useSelector((state) => state?.therapists?.paginateTherapists);

	useEffect(() => {
		setLoading(false);
		setLoadingTimes(true);
		const retrieveTimes = async () => {
			await retrieveFourDaysTimeslots(days, therapist, duration)
				.then((time) => {
					setThreeApproaches(approach.slice(0, 3));
					setTimes(time);
					setLoadingTimes(false);
					const fullPathArray = window.location.href.split('/').slice(-2)[0];
					if (fullPathArray.length > 1) {
						let nameFromHome = fullPathArray.slice(0, -1);
						nameFromHome = nameFromHome.includes('%20')
							? nameFromHome.replace(/%20/g, ' ')
							: nameFromHome;
						setNameFromHome(nameFromHome);
					}
				})
				.catch((err) => console.log('error retrieving times', err));
		};
		paginateTherapists?.length > 0 && retrieveTimes();
	}, [days, paginateTherapists, duration]);

	return (
		!loading && (
			<div key={Math.random()} className="container">
				<div className="team-box">
					<div className="therapist-infos">
						<div className="therapist-pic-wrapper ">
							<div className="image therapist-pic">
								<img className="pic" src={image} alt="" />
							</div>
						</div>
						<div className="team-content">
							<div className="">
								<h4 id="therapist-name" className="nome-therapist">
									{name}
								</h4>
							</div>
							<div className="row referencia-therapist">
								<p id="prof-therapist" className="prof-therapist">
									CRP: {crp}
								</p>
								<div className="nota-estrela">
									<img src={star} />
								</div>
								<p className="nota-therapist">
									5,0
									{/* <span style={{color: 'gray'}}className="rate-avarage">(10 avaliações)</span> */}
								</p>
							</div>
							<div className="therapist-approach">
								{approach?.map((approach, idx) => {
									return (
										<div key={Math.random()}>
											{!threeApproaches[idx + 1] ? (
												<p key={Math.random()} className="approach">
													{approach}{' '}
												</p>
											) : (
												<p key={Math.random()} className="approach">
													{approach} |{' '}
												</p>
											)}
										</div>
									);
								})}
							</div>
							<p id="domini-therapist" className="domini-therapist font-light">
								{domain_fields?.map((domain, idx) => {
									return idx < 3 && <p key={Math.random()}>{domain}</p>;
								})}
							</p>
							<p className="about-therapist font-light">
								<strong>Sobre mim: </strong>
								{about}
							</p>
							<p>
								<button
									className="expand-button"
									onClick={() => expandAboutMe(expand, index)}
								>
									<u>Conhecer {firstName} melhor</u>
								</button>
							</p>
						</div>
						<div className="calendar-container">
							{!loadingTimes ? (
								times?.map((time, index) => {
									const renderTimes =
										Object.keys(time).length === 0
											? ['-', '-', '-', '-', '-']
											: Object.keys(time);
									return (
										<div
											key={Math.random()}
											className="calendar-cell-container"
										>
											{renderTimes?.map((time) => {
												// console.log('time no map', time)
												return (
													<div
														key={Math.random()}
														className="calendar-cell"
													>
														<p
															onClick={() =>
																saveAndPreBook(
																	time,
																	therapist?.id,
																	days[index]
																)
															}
														>
															{time}
														</p>
													</div>
												);
											})}
										</div>
									);
								})
							) : (
								<div className="padding-spinner-time">
									<Spinner />
								</div>
							)}
						</div>
					</div>
					<TestimonialComponent therapistId={therapist?.id} />
				</div>
			</div>
		)
	);
};
