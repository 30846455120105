import { removeHtmlTags } from '../utils/General';

export const domain = {
	LOCALHOST: 'http://localhost:8000/api/',
	STAGE: 'https://therapy-stg.moodar.com.br/api/',
	PRODUCTION: 'https://webapp.moodar.com.br/api/',
};

const urlPrefix = domain.PRODUCTION;
// const urlPrefix = domain.STAGE;
// const urlPrefix = domain.LOCALHOST;

const url = (path) => urlPrefix.concat(path.join('/'), '/');
const stringUrl = (path) => urlPrefix.concat(path);

export default async (config) => {
	const { method, path, authToken, body, stringPath } = config;

	const opt = {
		headers: {
			Accept: 'application/json',
			Authorization: authToken && `Token ${authToken}`,
			'Content-Type': 'application/json',
			// samesite: 'None',
		},
		method,
		body: body && JSON.stringify(body),
	};

	return fetch(path ? url(path) : stringUrl(stringPath), opt)
		.then((res) => {
			if (res.ok) {
				const resContentType = res.headers.get('content-type');
				return resContentType === 'application/json' ? res.json() : {};
			} else {
				return serverErr(res);
			}
		})
		.catch((err) => Promise.reject(err));
};

const serverErr = async (res: Response) => {
	const canJson = res.headers.get('content-type') === 'application/json';
	if (canJson) {
		return res.json().then((response: any) => handleErrorMessagesFromAPI(response));
	} else {
		return res.text().then((response: string) => handleErrorMessagesFromAPI(response));
	}
};

const handleErrorMessagesFromAPI = (response: any) => {
	if (typeof response === 'string' && response !== '') {
		return Promise.reject(removeHtmlTags(response));
	} else if ('error' in response) {
		if (typeof response.error === 'object') {
			return handleObjectsError(response.error);
		}
		return Promise.reject(response.error);
	} else if ('detail' in response) {
		if (typeof response.detail === 'object') {
			return handleObjectsError(response.detail);
		}
		return Promise.reject(response.detail);
	} else if ('message' in response) {
		if (typeof response.message !== 'string') {
			if ('errors' in response.message && typeof response.message.errors === 'object') {
				return handleObjectsError(response.message.errors);
			} else if ('errors' in response.message) {
				return Promise.reject(response.message.errors);
			} else if (typeof response.message === 'object') {
				return handleObjectsError(response.message);
			}
		}
		return Promise.reject(response.message);
	} else if ('patient' in response) {
		if (typeof response.patient === 'object') {
			return handleObjectsError(response.patient);
		}
		return Promise.reject(String(response.patient));
	}
	return Promise.reject('Erro inesperado ocorreu');
};

const handleObjectsError = (errors: any) => {
	// por causa dos erros que a iugu retorna, criamos esse handleobjecterror e falta de padronização deles
	let allErrors: string[] = [];
	Object.keys(errors).map((key) => {
		if (typeof errors[key] === 'object') {
			allErrors.push(`${key}: ${errors[key].join(' - ')}`);
		} else if (typeof errors[key] === 'string') {
			allErrors.push(`${key}: ${errors[key]}`);
		}
	});
	return Promise.reject(allErrors.join(' | '));
};
