import fetchServer from './utils';

export const retrieveTherapists = (authToken: string) => {
	return fetchServer({
		method: 'GET',
		path: ['therapists'],
		// authToken
	});
};

export const retrieveTherapistAvailableDays = (authToken: string, therapistId: Number) => {
	return fetchServer({
		method: 'GET',
		path: ['appointments', `retrieve-therapist-available-days/${therapistId}`],
		authToken,
	});
};

export const getTherapistTestimonials = (therapistId: Number) => {
	return fetchServer({
		method: 'GET',
		path: ['feedback', `retrieve-testimonials/${therapistId}`],
		// authToken
	});
};
