import React from 'react';
import '../Therapist.css';

export const TitleComponent = () => {
	return (
		<div className="font">
			<div className="container">
				<div className="topcontent">
					<h2 className="title darkcolor">
						Encontre seu <strong>psicólogo(a)</strong>
					</h2>
					<div>
						<a
							className="login-link"
							href="https://video.moodar.com.br"
							target="_blank"
							rel="noopener noreferrer"
						>
							Faça login
						</a>{' '}
						ou{' '}
						<a className="register-link" href="/#/signup">
							Crie sua conta
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
