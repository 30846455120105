import { combineReducers } from "redux";
import SignInReducer from "./SignInReducer";
import SignInIDReducer from "./SignInIdReducer";
import TherapistReducer from './TherapistReducer';

export default combineReducers({
  signInForm: SignInReducer,
  ids: SignInIDReducer,
  therapists: TherapistReducer,
});
