/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { TherapistActions } from '../../../libs/redux/actions';
import { unaccent } from '../controller';

export const SearchComponent = (props) => {
	const [name, setName] = useState('');
	const [selectedApproach, setSelectedApproach] = useState('');
	const [selectedDomain, setSelectedDomain] = useState('');
	const [toFilter, setToFilter] = useState([]);
	const { domains, approaches, setIsFiltered, setLoading, setCurrentPage, nameFromHome } = props;
	const dispatch = useDispatch();
	const therapists = useSelector((state) => state?.therapists?.therapists);
	let filteredTherapists = useSelector((state) => state?.therapists?.filteredTherapists);

	useEffect(() => {
		if (nameFromHome.length !== '') {
			setName(nameFromHome);
			nameFilter(filteredTherapists, nameFromHome);
		}
	}, [nameFromHome]);

	const nameFilter = (filtered, name) => {
		setLoading(true);
		if (name !== '') {
			const therapistsToFilter = toFilter.length > 0 ? filtered : therapists;
			setIsFiltered(true);
			let filteredByName = therapistsToFilter?.filter((therapist) => {
				return unaccent(therapist?.name)?.toLowerCase().includes(name?.toLowerCase());
			});
			if (filteredByName?.length === 0) {
				filteredByName = therapistsToFilter?.filter((therapist) => {
					const lowerCaseDomains = therapist?.domain_fields?.map((domain) =>
						unaccent(domain)?.toLowerCase()
					);
					return lowerCaseDomains?.includes(name.toLowerCase());
				});
			}
			if (filteredByName?.length === 0) {
				filteredByName = therapistsToFilter?.filter((therapist) => {
					const lowerCaseApproach = therapist?.approach?.map((approach) =>
						unaccent(approach)?.toLowerCase()
					);
					return lowerCaseApproach?.includes(name);
				});
			}
			debounced(filteredByName);
		} else {
			setIsFiltered(false);
			if (toFilter.length === 0) {
				debounced(therapists);
			} else {
				generalSearch(true);
				debounced(filteredTherapists);
			}
		}
	};

	const deleteField = (cloned, field) => {
		for (var i = 0; i < cloned.length; i++) {
			if (cloned[i][field]) {
				cloned.splice(i, 1);
			}
		}
	};

	const deleteFieldAndSave = (field) => {
		const cloned = toFilter;
		deleteField(cloned, field);
		setToFilter(cloned);
	};

	const clearFilterOption = () => {
		return (
			<option value="" style={{ color: 'red' }}>
				Limpar filtro
			</option>
		);
	};

	const addToApproachFilter = (event) => {
		const value = event.target.value;
		if (value === '') {
			setSelectedApproach('');
			deleteFieldAndSave('approach');
		} else {
			const values = toFilter?.Object?.values();
			if (!values?.includes(value)) {
				deleteFieldAndSave('approach');
				setToFilter([...toFilter, { approach: value }]);
			}
			setSelectedApproach(event.target.value);
		}
	};

	const addToDomainFilter = (event) => {
		const value = event.target.value;
		if (value === '') {
			setSelectedDomain('');
			deleteFieldAndSave('domain_fields');
		} else {
			const values = toFilter?.Object?.values();
			if (!values?.includes(value)) {
				deleteFieldAndSave('domain_fields');
				setToFilter([...toFilter, { domain_fields: value }]);
			}
			setSelectedDomain(event.target.value);
		}
	};

	const generalSearch = (filteringBack) => {
		// Receive a boolean to know if it's called from click or from nameFilter function
		// Function filter by name, approach and domains
		// check if there's approach or domain to filter for
		setLoading(true);
		if (toFilter.length > 0) {
			toFilter.map((filter, idx) => {
				const field = Object.keys(filter);
				const values = Object.values(filter);
				// uses filteringBack to not call nameFilter again and prevent loop because it already came from nameFilter
				const filteredByName =
					idx === 0
						? filteringBack
							? therapists
							: nameFilter(therapists, unaccent(name))
						: filteredTherapists;

				// Filtering by approach and domain
				const filtered = filteredByName?.filter((therapist) => {
					return therapist?.[field[0]]?.includes(values[0]);
				});
				setIsFiltered(true);
				// Saving on filtered therapist redux property
				dispatch(TherapistActions.addFilteredTherapists(filtered));
				dispatch(TherapistActions.addPaginateTherapists(filtered));
				filteredTherapists = filtered;
				// setSelected(0);
				setCurrentPage(0);
				setLoading(false);
			});
		} else {
			setIsFiltered(false);
			setLoading(false);
			const filteredByName = nameFilter(filteredTherapists, unaccent(name));
			setCurrentPage(0);
			// setSelected(0);
			dispatch(TherapistActions.addFilteredTherapists(filteredByName));
			dispatch(TherapistActions.addPaginateTherapists(filteredByName));
		}
	};

	const debounced = useCallback(
		debounce((filtered) => {
			dispatch(TherapistActions.addFilteredTherapists(filtered));
			dispatch(TherapistActions.addPaginateTherapists(filtered));
			setLoading(false);
			// setSelected(0)
			setCurrentPage(0);
		}, 550),
		[]
	);

	const handleChange = (event) => {
		const value = unaccent(event?.target?.value);
		setName(event?.target?.value);
		nameFilter(filteredTherapists, value);
	};
	return (
		<div className="font">
			<div className="container">
				<div className="padding-top">
					<div className="col-md-12 bottom30 search-therapist row overwrite-row">
						<input
							value={name}
							className="first-input"
							type="text"
							placeholder="Procure por nome ou motivo"
							onChange={handleChange}
						/>
						<select
							className="second-input"
							type="text"
							value={selectedApproach}
							onChange={addToApproachFilter}
						>
							<option value="" disabled selected>
								Abordagem
							</option>
							{approaches?.map((approach) => {
								return (
									<option key={approach} value={approach}>
										{approach}
									</option>
								);
							})}
							{clearFilterOption()}
						</select>
						<select
							className="third-input"
							type="text"
							value={selectedDomain}
							onChange={addToDomainFilter}
						>
							<option value="" disabled selected>
								Área de Domínio
							</option>
							{domains?.map((domain) => {
								return (
									<option key={domain} value={domain}>
										{domain}
									</option>
								);
							})}
							{clearFilterOption()}
						</select>
						<button onClick={() => generalSearch(false)} className="input-button">
							Procurar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
