import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { Spinner, Toast } from '../../../commom-components';
import { NewSelect, NewTextInput } from '../../../commom-components/newtextinput/NewTextInput';
import TitleComponent from '../../../commom-components/title/TitleComponent';
import { LogoIMG } from '../../../assets/images/images';

import { SubmitButton } from '../../../commom-components/buttons/Buttons';
import styles from './newsignupform.module.css';
import {
	AllowedCompanyRegistrationOptions,
	isValidCpf,
	isValidEmail,
} from '../../../utils/General';
import AccessType from '../NewSignup/AccessType/AccessType';

const AcessTypeForm = (props) => {
	const { saveFirstData, history, signUpForm } = props;

	const [accessType, setAccessType] = useState(undefined);
	const [familyType, setFamilyType] = useState('');
	const [registration, setRegistration] = useState(undefined);
	const [company, setCompany] = useState(undefined);
	const [familyemail, setFamilyEmail] = useState(undefined);
	const [familycpf, setFamilyCpf] = useState(undefined);
	const [familyregistration, setFamilyRegistration] = useState(undefined);
	const [familycompany, setFamilyCompany] = useState(undefined);
	const [fetching, setFetching] = useState(false);

	const getValidatorSchema = () =>
		Yup.object().shape({
			accessType: Yup.string().required('Selecione seu tipo de acesso'),
		});

	const isValidFamilyInputs = () => {
		if (familyType === 'iscpf') {
			const emailIsValid = isValidEmail(familyemail);
			const cpfIsValid = isValidCpf(familycpf);

			let msg = [];
			if (!cpfIsValid) {
				msg.push('CPF é inválido');
			}
			if (!emailIsValid) {
				msg.push('Email é inválido');
			}
			return { error: !(emailIsValid && cpfIsValid), message: msg.join(', ') };
		} else if (familyType === 'isregistration') {
			const registrationIsValid = familyregistration.length > 0;
			const companyIsValid = familycompany;

			let msg = [];
			if (!registrationIsValid) {
				msg.push('Matrícula é inválida');
			}
			if (!companyIsValid) {
				msg.push('Escolha uma empresa');
			}
			return { error: !(companyIsValid && registrationIsValid), message: msg.join(', ') };
		} else {
			return false;
		}
	};

	const isValidCompanyInputs = () => {
		const registrationIsValid = registration && registration.length > 0;
		const companyIsValid = !!company;

		let msg = [];
		if (!registrationIsValid) {
			msg.push('Matrícula é inválida');
		}
		if (!companyIsValid) {
			msg.push('Escolha uma empresa');
		}
		return { error: !(companyIsValid && registrationIsValid), message: msg.join(', ') };
	};

	return (
		<div className={styles.register_content}>
			<Formik
				enableReinitialize
				initialValues={{
					accessType,
					registration,
					company,
					familyemail,
					familycpf,
					familyregistration,
					familycompany,
				}}
				validationSchema={() => getValidatorSchema()}
				onSubmit={(values) => {
					// const body = handleBodyToReq(values);
					setFetching(true);
					const newSignupForm = { ...signUpForm };
					newSignupForm['accessType'] = accessType;
					if (accessType === 'family') {
						// validar inputs
						const isValid = isValidFamilyInputs();
						if (!isValid.error) {
							newSignupForm['familyemail'] =
								familyType === 'iscpf' ? familyemail : '';
							newSignupForm['familycpf'] = familyType === 'iscpf' ? familycpf : '';
							newSignupForm['familyregistration'] =
								familyType === 'iscpf' ? '' : familyregistration;
							newSignupForm['familycompany'] =
								familyType === 'iscpf' ? '' : familycompany;
							setFetching(false);
							saveFirstData(newSignupForm);
							history.push({ pathname: '/conclude-signup' });
						} else {
							setFetching(false);
							Toast(isValid.message, 'error', 4000);
						}
					} else if (accessType === 'companyregistration') {
						const isValid = isValidCompanyInputs();
						if (!isValid.error) {
							newSignupForm['registration'] = registration;
							newSignupForm['company'] = company;
							setFetching(false);
							saveFirstData(newSignupForm);
							history.push({ pathname: '/conclude-signup' });
						} else {
							setFetching(false);
							Toast(isValid.message, 'error', 4000);
						}
					} else {
						// passar pra proxima
						saveFirstData(newSignupForm);
						history.push({ pathname: '/conclude-signup' });
					}
				}}
			>
				{() => (
					<Form className={styles.register_form}>
						<img src={LogoIMG} alt="Logo" />
						<TitleComponent text="Tipo de Acesso" />
						<NewSelect
							label={'Parceria'}
							name={'accessType'}
							setItem={setAccessType}
							options={[
								{ text: 'Selecione a parceria', value: 'Selecione a parceria' },
								{
									text: 'Tenho um familiar com parceria',
									value: 'family',
								},
								{
									text: 'Cadastrar com matrícula da empresa',
									value: 'companyregistration',
								},
								{ text: 'Não tenho vínculo', value: 'nobond' },
							]}
						/>
						{accessType === 'family' ? (
							<>
								<NewSelect
									label={'Qual o formato de cadastro?'}
									name={'familyType'}
									setItem={setFamilyType}
									options={[
										{
											text: 'Possuo a matrícula do meu familiar',
											value: 'isregistration',
										},
										{ text: 'Possuo outras informações', value: 'iscpf' },
									]}
								/>
								{familyType === 'iscpf' ? (
									<>
										<NewTextInput
											name={'familyemail'}
											type={'email'}
											placeholder={'exemplo@moodar.com.br'}
											label={'E-mail do meu familiar'}
											setItem={setFamilyEmail}
										/>
										<NewTextInput
											name={'familycpf'}
											type={'text'}
											placeholder={'000.000.000-00'}
											label={'CPF do meu familiar'}
											setItem={setFamilyCpf}
											mask={'999.999.999-99'}
										/>
									</>
								) : null}
								{familyType === 'isregistration' ? (
									<>
										<NewSelect
											label={'Empresa do meu familiar'}
											name={'familycompany'}
											setItem={setFamilyCompany}
											options={AllowedCompanyRegistrationOptions}
										/>
										<NewTextInput
											name={'familyregistration'}
											type={'text'}
											placeholder={'123-022322'}
											label={'Matrícula do meu familiar'}
											setItem={setFamilyRegistration}
										/>
									</>
								) : null}
							</>
						) : null}

						{accessType === 'companyregistration' ? (
							<>
								<NewSelect
									label={'Empresa'}
									name={'company'}
									setItem={setCompany}
									options={AllowedCompanyRegistrationOptions}
								/>
								<NewTextInput
									name={'registration'}
									type={'text'}
									placeholder={'123-022322'}
									label={'Matrícula'}
									setItem={setRegistration}
								/>
							</>
						) : null}

						{fetching ? (
							<Spinner />
						) : (
							<SubmitButton text="Continuar" handleClick={() => {}} />
						)}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AcessTypeForm;
