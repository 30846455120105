import React from 'react';
import Icon from '@material-ui/core/Icon';
import styles from './Pagination.module.css';


export const Pagination = (props) => {
  const { selected, pages, setPage, totalPages, start} = props;
  return <div className={styles.container}>
      {selected - 1 >= 0 && <Icon 
        className="dates-icon-left" 
        onClick={() => selected - 1 >= 0 && setPage(selected, -1)}>
        arrow_back_ios
      </Icon>}
      {pages?.slice(start, start + 5).map((page, index) =>
        <div 
          key={Math.random()}
          className={selected === page ? styles.selected : styles.number} 
          onClick={() => selected !== page && setPage(page, 0)}>{page + 1}
        </div>)}
      {selected + 1 < totalPages && <Icon 
        className="dates-icon-right" 
        onClick={() => selected + 1 < totalPages && setPage(selected, 1)}>
        arrow_forward_ios
      </Icon>}
    </div>
}