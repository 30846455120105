import React from 'react';
import { connect } from 'react-redux';
import { AuthController } from '../../libs/controllers';
import NewSignupForm from './NewSignupForm/NewSignupForm';
import LayoutNewSignup from '../../commom-components/layoutsignup/LayoutNewSignup';

// import NormalForm from './Forms/Form';
// import LinhaDeApoioForm from './Forms/LinhaDeApoioForm';

const SignUp = (props) => {
	const { history, saveFirstData, signUp } = props;

	// const fullPath =
	//   "https://cadastro.moodar.com.br/psicologo/15/linhadeapoio/#/signup";
	const fullPath = window.location.href;
	const splits = fullPath.split('/');
	const therapistId = splits.splice(-3)[0];

	localStorage.setItem('therapistId', therapistId);

	// return !linhaDeApoio ? (
	// 	<NormalForm history={history} saveFirstData={saveFirstData} signUp={signUp} />
	// ) : (
	// 	<LinhaDeApoioForm history={history} signUp={signUp} />
	// );

	return (
		<LayoutNewSignup>
			<NewSignupForm history={history} saveFirstData={saveFirstData} signUp={signUp} />
		</LayoutNewSignup>
	);
};

const mapDispatchToProps = (dispatch) => ({
	saveFirstData: (data) => AuthController.storeFirstData(dispatch, data),
});

export default connect(null, mapDispatchToProps)(SignUp);
