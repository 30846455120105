import React from "react";
import styles from "./Spinner.module.css";

export const Spinner = (props) => {
  return (
    <div className={styles.load}>
      <div className={styles.loader}></div>
    </div>
  );
};
