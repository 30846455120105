import React, { useState, useEffect } from 'react';
import { getTestimonials } from '../controller';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import { starfull } from '../../../assets/icons/icons.js';
import { FiveStarsComponent } from './FiveStarsComponent';
import '../Therapist.css';

export const TestimonialComponent = (props) => {
	const [testimonials, setTestimonials] = useState([]);
	const { therapistId } = props;
	useEffect(() => {
		getTestimonials(therapistId).then((res) => {
			setTestimonials(res);
		});
	}, []);

	return (
		<div className="testimonial-card-container">
			{testimonials?.map((testimonial, index) => {
				const date = moment(testimonial?.created_at).format('DD[,] MMM [de] YYYY');
				return (
					<div key={index} className="testimonial-card">
						<text className="testimonial-title">
							{testimonial?.user?.split(' ')[0]}
						</text>
						<FiveStarsComponent starfull={starfull} />
						<div className="testimonial-text">
							<text>{testimonial?.testmonial}</text>
						</div>
						<div className="testimonial-date">
							<text>{date}</text>
						</div>
					</div>
				);
			})}
		</div>
	);
};
