import React from 'react';
import { connect } from 'react-redux';
import LayoutNewSignup from '../../../../commom-components/layoutsignup/LayoutNewSignup';
import { AuthController } from '../../../../libs/controllers';
import ConcludeSignupForm from '../../NewSignupForm/ConcludeSignupForm';

// import NormalForm from './Forms/Form';
// import LinhaDeApoioForm from './Forms/LinhaDeApoioForm';

const ConcludeSignupScreen = (props) => {
	const { history, saveFirstData, signUp, signInForm } = props;

	// return !linhaDeApoio ? (
	// 	<NormalForm history={history} saveFirstData={saveFirstData} signUp={signUp} />
	// ) : (
	// 	<LinhaDeApoioForm history={history} signUp={signUp} />
	// );

	return (
		<LayoutNewSignup>
			<ConcludeSignupForm
				history={history}
				saveFirstData={saveFirstData}
				signUp={signUp}
				signUpForm={signInForm}
			/>
		</LayoutNewSignup>
	);
};

const mapStateToProps = (state) => {
	const { signInForm } = state;
	return { signInForm };
};
const mapDispatchToProps = (dispatch) => ({
	signUp: (data) => AuthController.signUp(dispatch, data),
	saveFirstData: (data) => AuthController.storeFirstData(dispatch, data),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConcludeSignupScreen);
