// @flow
import fetchServer from './utils';

export const retrieveTimeslots = (authToken, date, id, duration) => {
	return fetchServer({
		method: 'GET',
		path: ['appointments', 'v2', 'retrieve-therapist-timeslots', date, id, duration],
		authToken,
	});
};

export const retrieveFourDaysTimeslots = (date, id, duration) => {
	return fetchServer({
		method: 'GET',
		stringPath: `appointments/v2/retrieve-four-days-timeslots/${date}/${id}/${duration}`,
		// authToken
	});
};
