// @flow
import fetchServer from './utils';

export const signupParticipant = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-participant'],
		body,
	});
};

export const signinParticipant = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signin-participant'],
		body,
	});
};

export const signUpAnonymously = ({ name, uid }) => {
	const body = { name, uid };
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-free-patient'],
		body,
	});
};

export const signUpCompanyPatient = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-company-patient'],
		body,
	});
};

export const signUpFamilyPatient = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-family-patient'],
		body,
	});
};

export const signUpJohnsonPatient = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-johnson-patient'],
		body,
	});
};

export const signUpSelfitPatient = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-selfit-patient'],
		body,
	});
};

export const signUpSelfitAlunoPatient = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-selfit-aluno-patient'],
		body,
	});
};

export const signUpLinhaDeApoio = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'signup-linhadeapoio-patient'],
		body,
	});
};

export const verifyPatientCompany = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'verify-patient-company'],
		body,
	});
};

export const retrieveSession = (authToken: string) => {
	return fetchServer({
		method: 'GET',
		path: ['auth', 'retrieve-session'],
		authToken,
	});
};

export const updateSession = (authToken: string, body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'v2', 'update-session'],
		authToken,
		body,
	});
};

export const retrieveCompanies = () => {
	return fetchServer({
		method: 'GET',
		path: ['corporate', 'companies'],
	});
};

export const verifyEmail = (body: Object) => {
	return fetchServer({
		method: 'POST',
		path: ['auth', 'verify-email'],
		body,
	});
};

export const verifyPatientEmail = (email, cpf) => {
	return fetchServer({
		method: 'POST',
		stringPath: 'auth/verify-patient-company',
		body: { email, cpf },
	});
};
