import { SignInTypes } from "../types";

const initialState = {
  ids: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SignInTypes.SAVE_USER_AND_THERAPIST_ID:
      return {
        ...payload,
      };
    case SignInTypes.CLEAR_USER_AND_THERAPIST_ID:
      return {
        ...state,
      };
    default:
      return state;
  }
};
