import React from 'react';

export const FiveStarsComponent = (props) => {
    const { starfull } = props;
    return (
        <div className="star-container">
            <div className="rating">
                <img src={starfull} />
            </div>
            <div className="rating">
                <img src={starfull} />
            </div>
            <div className="rating">
                <img src={starfull} />
            </div>
            <div className="rating">
                <img src={starfull} />
            </div>
            <div className="rating">
                <img src={starfull} />
            </div>
        </div>
    )
}