import axios from "axios";

export const retrieveToken = (patientId) => {
  return (
    axios
      .get(`https://webapp.moodar.com.br/api/auth/retrieve-token/${patientId}`)
      // return axios.get(`https://dev.moodar.com.br/api/auth/retrieve-token/${patientId}`)
      .then((res) => {
        // console.log("RETORNOU ", res);
        const token = res.data.token;
        return token;
      })
  );
};

export const retrieveAgenda = (day, therapistId, authToken) => {
  const config = { headers: { Authorization: `Token ${authToken}` } };
  return (
    axios
      .get(
        `https://webapp.moodar.com.br/api/appointments/retrieve-therapist-timeslots/${day}/${therapistId}`,
        config
      )
      // return axios.get(`https://dev.moodar.com.br/api/appointments/retrieve-therapist-timeslots/${day}/${therapistId}`, config)
      .then((res) => {
        // console.log("RETORNOU ", res);
        return res.data;
      })
      .catch((error) => {
        // console.log(
        //   "DEU ERRO: ",
        //   error,
        //   error.response,
        //   error.status,
        //   error.name,
        //   error.mesage
        // );
        if (!error.response) {
          throw "Ocorreu um erro na rede. Não há conexão com a internet.";
        } else {
          const errorResponse = error.response.data.error;
          throw errorResponse;
        }
      })
  );
};

export const preBookSchedule = (
  schedule,
  patientId,
  therapistId,
  authToken
) => {
  const config = { headers: { Authorization: `Token ${authToken}` } };
  const body = {
    patient: patientId,
    therapist: therapistId,
    schedule,
    duration: 40,
  };
  return (
    axios
      .post(
        `https://webapp.moodar.com.br/api/appointments/pre-book`,
        body,
        config
      )
      // return axios.post(`https://dev.moodar.com.br/api/appointments/pre-book`, body, config)
      .then((res) => {
        // console.log("RETORNOU ", res);
        return res.data;
      })
      .catch((error) => {
        const errorResponse = error.response.data.error;
        throw errorResponse;
      })
  );
};
