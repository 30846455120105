import { TherapistAPI, AppointmentsAPI } from '../../server';
import moment from 'moment';

export const fetchTherapists = (authToken) => {
	// Corrigindo o erro de nao poder fazer .includes ao vir null da session do servidor
	return TherapistAPI.retrieveTherapists(authToken)
		.then((res) => {
			return Promise.all(res.therapists);
		})
		.catch((err) => {
			// console.log(err);
			throw new Error(err);
		});
};

export const retrieveTimeslots = async (authToken, days, therapist) => {
	let timesForDays = [];
	for (var i = 0; i < days.length; i++) {
		await AppointmentsAPI.retrieveTimeslots(authToken, days[i], therapist?.id, 40)
			.then((res) => {
				timesForDays.push(res);
			})
			.catch((err) => {
				timesForDays.push({});
			});
	}
	return timesForDays;
};

export const retrieveFourDaysTimeslots = async (days, therapist, duration = 40) => {
	return await AppointmentsAPI.retrieveFourDaysTimeslots(
		moment(days[0]).subtract(1, 'days').format('YYYY-MM-DD'),
		therapist?.id,
		duration
	).then((res) => {
		let timesForDays = [];
		for (var i = 0; i < 4; i++) {
			const dates = Object.values(res[i]);
			timesForDays.push(Object.values(dates)[0]);
		}
		return timesForDays;
	});
};

export const unaccent = (word: string) => {
	return word?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
};

export const getTestimonials = (therapistId: Number) => {
	return TherapistAPI.getTherapistTestimonials(therapistId).then((res) => {
		console.log('res do get therapist testimonials', res);
		return res;
	});
};
