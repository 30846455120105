import React from 'react';
import { Field } from 'formik';
import styles from './Buttons.module.css';

export const CustomButtom = (props) => {
	const { title, color, func } = props;
	return (
		<button className={styles.button} style={{ backgroundColor: color }} onClick={func}>
			{title}
		</button>
	);
};

export const FormButtom = (props) => {
	const { title, color, click } = props;
	return (
		<div className={styles.formButton}>
			<button
				type="submit"
				className={styles.button}
				style={{ backgroundColor: color }}
				onClick={click}
			>
				{title}
			</button>
		</div>
	);
};

export const SubmitButton = ({ text, handleClick }) => {
	return (
		<div>
			<button type="submit" className={styles.new_formbutton} onClick={handleClick}>
				{text}
			</button>
		</div>
	);
};
