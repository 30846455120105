import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TherapistActions } from '../../../libs/redux/actions';
import { Pagination } from '../../../commom-components';


export const PaginateTherapists = (props) => {
  const { perPage, isFiltered, setLoading, currentPage, setCurrentPage, selected } = props;
  const [limit, setLimit] = useState(perPage);
  // const [selected, setSelected] = useState(0);
  const [pages, setPages] = useState([]);
  // const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [start, setStart] = useState(0)
  const dispatch = useDispatch();
  const therapists = useSelector(state => state?.therapists?.therapists)
  const filteredTherapists = useSelector(state => state?.therapists?.filteredTherapists);

  const setPage = (page, addOrSubtract) => {
    const nextOrPrev = page + addOrSubtract;
    if (nextOrPrev > 3) {
      setStart(start + addOrSubtract)
    } else {
      setStart(0)
    }
    setCurrentPage(nextOrPrev);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    const start = currentPage * perPage;
    const end = start + perPage;
    const arrayToFilter = isFiltered ? filteredTherapists : therapists;
    const allPages = isFiltered ? Math.ceil(filteredTherapists?.length / limit) : Math.ceil(therapists?.length / limit)
    setTotalPages(allPages)
    const arrayPages = [];
    for(let i = 0; i < allPages; i++) {
      arrayPages.push(i);
    }
    setPages(arrayPages);
    dispatch(TherapistActions.addPaginateTherapists(arrayToFilter?.slice(start, end)));
    setLoading(false)
  }, [currentPage, selected, isFiltered, filteredTherapists])

  return <Pagination 
    pages={pages} 
    selected={currentPage} 
    totalPages={totalPages} 
    setPage={setPage}
    start={start}  
  />
}